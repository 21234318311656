import styled from 'styled-components';
import { accent } from 'constants/theme';

export const Container = styled.form`
  display: flex;
  padding: 2rem 4rem;
  justify-content: center;
  align-items: center;
`;

export const TextInput = styled.input.attrs({ type: 'text' })`
  border: 2px solid ${accent};
  background: none;
  width: 240px;
  padding: 7px 5px 4px;
  color: ${accent};
  text-transform: uppercase;
  font-size: 1.5em;
  font-family: 'Tungsten';

  &::placeholder {
    color: ${accent};
  }
`;

export const FormSubmit = styled.input.attrs({ type: 'submit' })`
  background: ${accent};
  border: none;
  outline: none;
  color: #fff;
  font-family: 'Tungsten';
  text-transform: uppercase;
  font-size: 1.5em;
  padding: 8px 13px 7px;
`;
