import React from 'react';
import styled from 'styled-components';
import SocialLink from 'components/socialicons';

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto;
  max-width: 250px;
`;

const Item = styled.li`
  display: block;
`;

export default () => (
  <List>
    <Item>
      <SocialLink.Instagram
        url="https://www.instagram.com/lauvsongs/"
        id="instagram_Social_BTN"
      />
    </Item>
    <Item>
      <SocialLink.Facebook
        url="https://www.facebook.com/lauvsongs/"
        id="facebook_Social_BTN"
      />
    </Item>
    <Item>
      <SocialLink.Twitter
        url="https://www.facebook.com/lauvsongs/"
        id="twitter_Social_BTN"
      />
    </Item>
    <Item>
      <SocialLink.Youtube
        url="https://www.youtube.com/channel/UCfLdIEPs1tYj4ieEdJnyNyw"
        id="youtube_DSP_BTN"
      />
    </Item>
  </List>
);
