import React, { Component } from 'react';
import Layout from 'components/layout';
import Box from 'components/box';
import { graphql } from 'gatsby';
import Middle from 'components/middle';
import Bottom from 'components/bottom';
import TNW from 'components/tnw';
import SignUp from 'components/signup';
import Icons from 'components/icons';
import IGFeed from 'components/igfeed';
import axios from 'axios';

class Index extends Component {
  state = {
    ip: {
      country_name: 'default',
    },
  };

  componentDidMount() {
    this.getIpData();
  }

  getIpData = async () => {
    const result = await axios.get(
      'https://api.ipdata.co/?api-key=ee854e25d75d648ddab48f37e0fd930ed8b3c5dffaccd407f8393006'
    );

    this.setState({
      ip: result.data,
    });
  };

  render() {
    return (
      <Layout country={this.state.ip}>
        <Middle>
          <TNW country={this.state.ip} />
        </Middle>
        <Bottom>
          <Box>
            <SignUp />
            <Icons />
            <IGFeed />
            <a
              href="https://lauvsongs.com/privacy-policy.html"
              style={{
                textAlign: 'center',
                textTransform: 'uppercase',
                margin: '20px 0',
                display: 'block',
              }}
            >
              Privacy Policy
            </a>
          </Box>
        </Bottom>
      </Layout>
    );
  }
}

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
