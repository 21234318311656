import React, { Component, Fragment } from 'react';
import { Container, TextInput, FormSubmit } from './signup.css';
import PrivacyPolicy from '../privacy-policy';

class SignUp extends Component {
  state = {
    showModal: false,
  };

  handleOpenModal = e => {
    e.preventDefault();
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };
  render() {
    return (
      <Fragment>
        <Container id="mailinglist_Signup_FORM">
          <TextInput placeholder="Enter Email Address" />
          <FormSubmit value="Join" />
        </Container>
        <button
          style={{
            padding: 0,
            border: 'none',
            textTransform: 'uppercase',
            fontSize: '.9em',
            background: 'none',
            fontFamily: 'Tungsten',
            borderBottom: '1px solid #d789ae',
            color: '#d789ae',
          }}
          onClick={this.handleOpenModal}
        >
          Terms
        </button>
        <PrivacyPolicy
          showModal={this.state.showModal}
          handleCloseModal={this.handleCloseModal}
        />
      </Fragment>
    );
  }
}

export default SignUp;
