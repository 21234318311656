import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Title = styled.a`
  font-size: 1.5em;
  text-transform: uppercase;
  margin-top: 150px;
  display: block;
  text-decoration: none;
  &:hover {
    color: #000;
  }
`;

const Grid = styled.div`
  display: flex;
  width: 100%;
  max-width: 1180px;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;

  > div {
    padding: 1px 2px;
    width: calc(20% - 4px);
    display: flex;
    align-items: center;
    justify-content: stretch;
    overflow: hidden;
    position: relative;

    @media (max-width: 600px) {
      width: calc(100% / 2 - 4px);
    }

    &:after {
      content: ' ';
      display: block;
      padding-bottom: 100%;
    }

    a {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ddd;
      img {
        transition: all 230ms ease-out;
        width: 100%;
      }
      &:hover {
        img {
          transform: scale(1.1);
          opacity: 0.75;
        }
      }
    }
  }
`;

const ENDPOINT =
  'https://api.instagram.com/v1/users/self/media/recent/?access_token=1935691101.fc09df8.c4a40839d4b44f8f959b740c31da92e5';

class IGFeed extends Component {
  state = {
    hasLoaded: false,
    data: [],
  };

  componentDidMount() {
    axios.get(ENDPOINT).then(res => {
      this.setState({
        hasLoaded: true,
        data: res.data.data,
      });
    });
  }

  render() {
    const { hasLoaded, data } = this.state;
    return (
      <Fragment>
        <Title
          href="https://instagram.com/lauvsongs"
          target="_blank"
          rel="noopener noreferrer"
        >
          @LauvSongs on Instagram
        </Title>
        {hasLoaded && (
          <Grid>
            {data.map(Photo => (
              <div key={Photo.id}>
                <a href={Photo.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={Photo.images.standard_resolution.url}
                    alt={Photo.id}
                  />
                </a>
              </div>
            ))}
          </Grid>
        )}
      </Fragment>
    );
  }
}

export default IGFeed;
