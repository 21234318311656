import styled from 'styled-components';
import BG from '../../../static/bluredbg.jpg';

export const Background = styled.div`
  background-image: url(${BG});
  background-position: middle bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 310px;
  padding-bottom: 110px;

  @media (max-width: 700px) {
    padding-top: 30px;
    padding-bottom: 60px;
  }
`;
